import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  AutocompleteRenderInputParams,
  Box,
} from "@mui/material";
import { Store } from "./backendTypes";

interface StoreSelectionDialogProps {
  open: boolean;
  onClose: () => void;
  stores: Store[];
  selectedStores: Store[] | null;
  onStoreSelect: React.Dispatch<React.SetStateAction<Store[] | null>>;
  onUpdate: () => void;
}

const StoreSelectionDialog: React.FC<StoreSelectionDialogProps> = ({
  open,
  onClose,
  stores,
  selectedStores,
  onStoreSelect,
  onUpdate,
}) => {
  const [filteredStores, setFilteredStores] = useState<Store[]>(stores);
  const [inputValue, setInputValue] = useState("");

  const ListboxComponent = React.forwardRef<
    HTMLUListElement,
    React.HTMLAttributes<HTMLUListElement>
  >((props, ref) => {
    const { children, ...other } = props;

    return (
      <ul
        ref={ref}
        {...other}
        style={{
          padding: 0,
          margin: 0,
          listStyle: "none",
          maxHeight: "60vh",
          overflow: "auto",
        }}
      >
        <Box
          component="li"
          sx={{
            p: 1,
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onStoreSelect(filteredStores);
            }}
            type="button"
            size="small"
            fullWidth
          >
            Select All {filteredStores.length} Stores
          </Button>
        </Box>
        {children}
      </ul>
    );
  });

  ListboxComponent.displayName = "ListboxComponent";

  const handleStoreChange = (
    _event: React.SyntheticEvent,
    newValue: Store[]
  ) => {
    onStoreSelect(newValue);
  };

  const handleInputChange = (
    _event: React.SyntheticEvent | null,
    value: string
  ) => {
    setInputValue(value);
    const filtered = stores.filter((store) =>
      `${store.name} ${store.url}`.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredStores(filtered);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle className="text-xl font-semibold border-b px-6 py-4">
        Add stores to organisation
      </DialogTitle>
      <DialogContent className="p-6" style={{ minHeight: "70vh" }}>
        <div className="h-full flex flex-col">
          <Autocomplete<Store, true>
            disablePortal
            id="combo-stores"
            options={stores}
            className="w-full"
            getOptionLabel={(option: Store) => `${option.name} ${option.url}`}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField {...params} label="Store" />
            )}
            multiple
            value={selectedStores ?? []}
            onChange={handleStoreChange}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            ListboxComponent={ListboxComponent}
            isOptionEqualToValue={(option, value) => option.csid === value.csid}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                onClick={(e) => {
                  e.preventDefault();
                  const newValue = selected
                    ? selectedStores?.filter(
                        (store) => store.csid !== option.csid
                      ) ?? []
                    : [...(selectedStores ?? []), option];
                  onStoreSelect(newValue);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 16px",
                  cursor: "pointer",
                  listStyle: "none",
                  backgroundColor: selected
                    ? "rgba(25, 118, 210, 0.08)"
                    : undefined,
                }}
              >
                <div>
                  <div style={{ fontWeight: 500 }}>{option.name}</div>
                  <div style={{ fontSize: "0.875rem", color: "#666" }}>
                    {option.url}
                  </div>
                </div>
              </li>
            )}
            popupIcon={null}
            sx={{
              "& .MuiAutocomplete-listbox": {
                padding: 0,
                "& ul": {
                  padding: 0,
                  margin: 0,
                  listStyle: "none",
                },
              },
            }}
          />
          <div className="mt-4 text-sm text-gray-500">
            {selectedStores?.length ?? 0} stores selected
          </div>
        </div>
      </DialogContent>
      <DialogActions className="border-t px-6 py-4">
        <Button onClick={onClose} className="mr-2" type="button">
          Cancel
        </Button>
        <Button onClick={onUpdate} disabled={!selectedStores} type="button">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StoreSelectionDialog;
