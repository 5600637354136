import {
  Autocomplete,
  Box,
  Paper,
  TextField,
  Typography,
  Button,
  TableCell,
  TableRow,
  Alert,
  Collapse,
  Input,
  IconButton,
} from "@mui/material";

import { DateTime } from "luxon";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { SetStateAction, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch as useDispatch } from "./store";
import TUtils from "./TUtils";

import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";

import {
  APIError,
  EventInfoExt,
  EventInfoListResponse,
  EventInfoTotals,
  Store,
} from "./backendTypes";
import { createTable, HeadCell, Order } from "./tableUtils";
import { listEvents } from "./reducers/events";
import { listStores } from "./reducers/stores";

const headerStyle = {
  paddingTop: "2em",
  paddingBottom: "1em",
  paddingLeft: "22px",
};

const defaultStyle = {
  paddingLeft: "16px",
};

const searchBoxStyle = {
  paddingLeft: "16px",
};

const searchInputStyle = {
  paddingLeft: "8px",
};

function qualifiedStoreName(store: Store) {
  const name =
    store.url.length > 0
      ? `${store.name} - ${store.description} (${store.url})`
      : `${store.name} - ${store.description}`;
  return name + ` - cluster ${store.pg_idx + 1}`;
}

const orderHeadCells: HeadCell<EventInfoExt>[] = [
  {
    id: "guid",
    numeric: false,
    disablePadding: false,
    label: "GUID",
  },
  // {
  //   id: 'utid',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'UTID',
  // },
  {
    id: "event",
    numeric: true,
    disablePadding: false,
    label: "Timestamp",
  },
  // {
  //   id: 'interaction',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Interaction',
  // },
  // {
  //   id: 'loc',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Location',
  // },
  {
    id: "nitems",
    numeric: true,
    disablePadding: false,
    label: "Num(items)",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Amount (EUR)",
  },
];

const timezones = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Asmera",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Timbuktu",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/ComodRivadavia",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Atka",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Buenos_Aires",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Catamarca",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Coral_Harbour",
  "America/Cordoba",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Ensenada",
  "America/Fort_Nelson",
  "America/Fort_Wayne",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Jujuy",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Knox_IN",
  "America/Kralendijk",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Louisville",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Mendoza",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montreal",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Nuuk",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Acre",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Rosario",
  "America/Santa_Isabel",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Shiprock",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Virgin",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/South_Pole",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Ashkhabad",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Calcutta",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Chongqing",
  "Asia/Chungking",
  "Asia/Colombo",
  "Asia/Dacca",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Harbin",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Istanbul",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kashgar",
  "Asia/Kathmandu",
  "Asia/Katmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macao",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qostanay",
  "Asia/Qyzylorda",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Saigon",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Tel_Aviv",
  "Asia/Thimbu",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ujung_Pandang",
  "Asia/Ulaanbaatar",
  "Asia/Ulan_Bator",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yangon",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faeroe",
  "Atlantic/Faroe",
  "Atlantic/Jan_Mayen",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/ACT",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Canberra",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/LHI",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/NSW",
  "Australia/North",
  "Australia/Perth",
  "Australia/Queensland",
  "Australia/South",
  "Australia/Sydney",
  "Australia/Tasmania",
  "Australia/Victoria",
  "Australia/West",
  "Australia/Yancowinna",
  "Brazil/Acre",
  "Brazil/DeNoronha",
  "Brazil/East",
  "Brazil/West",
  "CET",
  "CST6CDT",
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "Chile/Continental",
  "Chile/EasterIsland",
  "Cuba",
  "EET",
  "EST",
  "EST5EDT",
  "Egypt",
  "Eire",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belfast",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Kirov",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Nicosia",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Tiraspol",
  "Europe/Ulyanovsk",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "Factory",
  "GB",
  "GB-Eire",
  "GMT",
  "GMT+0",
  "GMT-0",
  "GMT0",
  "Greenwich",
  "HST",
  "Hongkong",
  "Iceland",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Iran",
  "Israel",
  "Jamaica",
  "Japan",
  "Kwajalein",
  "Libya",
  "MET",
  "MST",
  "MST7MDT",
  "Mexico/BajaNorte",
  "Mexico/BajaSur",
  "Mexico/General",
  "NZ",
  "NZ-CHAT",
  "Navajo",
  "PRC",
  "PST8PDT",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kanton",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Ponape",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Samoa",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Truk",
  "Pacific/Wake",
  "Pacific/Wallis",
  "Pacific/Yap",
  "Poland",
  "Portugal",
  "ROC",
  "ROK",
  "Singapore",
  "Turkey",
  "UCT",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Samoa",
  "UTC",
  "Universal",
  "W-SU",
  "WET",
  "Zulu",
];

function OrderStatus() {
  const scope = "kytron::read_events";

  // redux state
  const dispatch = useDispatch();
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );

  const session = useSelector((state: RootState) => state.user.session);
  const stores = useSelector((state: RootState) => state.stores.stores);

  useEffect(() => {
    if (stores.length == 0) {
      dispatch(listStores({ scope, session, backendUrl }));
    }
  }, []);

  const defaultZone = DateTime.local().zoneName;

  const [selectedDateRange, setSelectedDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
  });

  const [selectedTimezone, setSelectedTimezone] = useState<string>(defaultZone);
  const [orders, setOrders] = useState<Array<EventInfoExt>>([]);
  const [orderRows, setOrderRows] = useState<Array<EventInfoExt>>([]);
  const [totals, setTotals] = useState<EventInfoTotals | null>();

  const [orderOrder, setOrderOrder] = useState<Order>("desc");
  const [orderOrderBy, setOrderOrderBy] = useState<keyof EventInfoExt>("event");
  const [ordersPage, setOrdersPage] = useState(0);
  const [orderRowsPerPage, setOrderRowsPerPage] = useState(5);

  const [selectedStoreName, setSelectedStoreName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [orderSearchText, setOrderSearchText] = useState("");

  const handleUpdate = () => {
    const selectedStores = stores.filter(
      (store) => qualifiedStoreName(store) === selectedStoreName
    );
    const csids = selectedStores.map((x) => x.csid);
    dispatch(
      listEvents({
        csids,
        interactions: ["purchase"],
        collect_start: collectStart,
        collect_end: collectEnd,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then((orderResponse: EventInfoListResponse) => {
        console.log("Order info retrieved!");
        setOrders(orderResponse.events);
        setTotals(orderResponse.totals);
        console.log(orderResponse.events.length);
      })
      .catch((err: APIError) => {
        setErrorMessage(
          `Unable to retrieve heartbeat for store with csid '${selectedStoreName}': ${err.error}`
        );
      });
  };

  useEffect(() => {
    if (orderSearchText.length !== 0) {
      setOrderRows(TUtils.filterArrayByString(orders, orderSearchText));
      setOrdersPage(0);
    } else {
      setOrderRows(orders);
    }
  }, [orders, orderSearchText]);

  const collectStart = useMemo(() => {
    if (DateTime.fromISO(selectedDateRange.startDate).isValid) {
      const selDT = DateTime.fromISO(selectedDateRange.startDate);
      const selTS =
        selectedTimezone?.length < 1 ? defaultZone : selectedTimezone;
      const startTime = DateTime.fromObject(
        {
          year: selDT.year,
          month: selDT.month,
          day: selDT.day,
        },
        { zone: selTS }
      ).startOf("day");
      const utcStart = startTime.toUTC().toFormat("yyyy-MM-dd HH:mm:ss");
      return utcStart;
    }
    return DateTime.local()
      .startOf("day")
      .toUTC()
      .toFormat("yyyy-MM-dd HH:mm:ss");
  }, [selectedTimezone, selectedDateRange]);

  const collectEnd = useMemo(() => {
    if (DateTime.fromISO(selectedDateRange.endDate).isValid) {
      const selDT = DateTime.fromISO(selectedDateRange.endDate);
      const selTS =
        selectedTimezone?.length < 1 ? defaultZone : selectedTimezone;
      const endTime = DateTime.fromObject(
        {
          year: selDT.year,
          month: selDT.month,
          day: selDT.day,
        },
        { zone: selTS }
      ).endOf("day");
      const utcEnd = endTime.toUTC().toFormat("yyyy-MM-dd HH:mm:ss");
      return utcEnd;
    }
    return DateTime.local()
      .endOf("day")
      .toUTC()
      .toFormat("yyyy-MM-dd HH:mm:ss");
  }, [selectedTimezone, selectedDateRange]);

  const handleOrdersRequestSort = (
    _order: React.MouseEvent<unknown>,
    property: keyof EventInfoExt
  ) => {
    const isAsc = orderOrderBy === property && orderOrder === "asc";
    setOrderOrder(isAsc ? "desc" : "asc");
    setOrderOrderBy(property);
  };

  const handleOrdersChangePage = (_order: unknown, newPage: number) => {
    setOrdersPage(newPage);
  };

  const handleOrdersChangeRowsPerPage = (
    order: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderRowsPerPage(parseInt(order.target.value, 10));
    setOrdersPage(0);
  };

  const orderToCell = (row: EventInfoExt) => {
    return (
      <TableRow hover key={`${row.guid}_${row.event}`}>
        <TableCell component="th" scope="row">
          {row.guid}
        </TableCell>
        {/* <TableCell align="right">{row.utid}</TableCell> */}
        <TableCell align="right">
          {DateTime.fromFormat(row.event, "yyyy-MM-dd HH:mm:ss", {
            zone: "UTC",
          })
            .setZone(
              selectedTimezone?.length > 1 ? selectedTimezone : defaultZone
            )
            .toLocaleString(DateTime.DATETIME_FULL)}
        </TableCell>
        {/* <TableCell align="right">{row.interaction}</TableCell>
        <TableCell align="right">{row.loc}</TableCell> */}
        <TableCell align="right">{row.nitems}</TableCell>
        <TableCell align="right">{row.amount.toFixed(2)}</TableCell>
      </TableRow>
    );
  };

  const dailyTotals = useMemo(() => {
    const totals = orders.reduce((prev, curr) => {
      const date = DateTime.fromFormat(curr.event, "yyyy-MM-dd HH:mm:ss", {
        zone: "UTC",
      }).setZone(selectedTimezone?.length > 1 ? selectedTimezone : defaultZone);
      const key = date.toFormat("yyyy-MM-dd");
      console.log(key);
      const nitems = prev[key]?.nitems || 0;
      const amount = prev[key]?.amount || 0;
      const orders = prev[key]?.orders || 0;
      prev[key] = {
        nitems: nitems + curr.nitems,
        amount: amount + curr.amount,
        orders: orders + 1,
      };
      return prev;
    }, {} as Record<string, EventInfoTotals>);
    return totals;
  }, [orders]);

  const exportToCSV = () => {
    const headers = ["GUID", "Timestamp", "Num(items)", "Amount (EUR)"];
    const rows = orders.map((x) => [
      x.guid,
      `"${DateTime.fromFormat(x.event, "yyyy-MM-dd HH:mm:ss", {
        zone: "UTC",
      })
        .setZone(selectedTimezone?.length > 1 ? selectedTimezone : defaultZone)
        .toLocaleString(DateTime.DATETIME_FULL)}"`,
      x.nitems,
      x.amount.toFixed(2),
    ]);
    const csv = [headers, ...rows].map((x) => x.join(",")).join("\n");
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `orders_${selectedDateRange.startDate.split("T")[0]}_${
      selectedDateRange.endDate.split("T")[0]
    }_${selectedStoreName}.csv`;

    a.click();
  };

  const exportTotalsToCSV = () => {
    const headers = ["Date", "Orders", "Amount (EUR)", "Num(items)"];
    const rows = Object.entries(dailyTotals).map(([key, value]) => [
      key,
      value.orders,
      value.amount.toFixed(2),
      value.nitems,
    ]);
    const csv = [headers, ...rows].map((x) => x.join(",")).join("\n");
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    // file name should have the selected dates in format "yyyy-MM-dd" and the store name
    a.download = `daily_totals_${selectedDateRange.startDate.split("T")[0]}_${
      selectedDateRange.endDate.split("T")[0]
    }_${selectedStoreName}.csv`;
    a.click();
  };

  const emptyOrderRows =
    ordersPage > 0
      ? Math.max(0, (1 + ordersPage) * orderRowsPerPage - orderRows.length)
      : 0;

  return (
    <div>
      <div className="centeredn">
        <Box sx={{ width: "100%" }}>
          <Collapse in={errorMessage.length > 0}>
            <Alert
              variant="outlined"
              severity="error"
              onClose={() => {
                setErrorMessage("");
              }}
            >
              {errorMessage}
            </Alert>
          </Collapse>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1 }}
              style={headerStyle}
            >
              Order-Information
            </Typography>
            {/* <IconButton aria-label="cart" sx={{ padding: '1em' }}>
              <StyledBadge badgeContent={totals?.nitems || 0} color="secondary">
                <ShoppingCartIcon />
              </StyledBadge>
            </IconButton> */}
            <div className="hbox">
              <Autocomplete
                disablePortal
                id="combo-zones"
                options={timezones}
                sx={{ width: "100%", paddingRight: "1em", marginBottom: "1em" }}
                renderInput={(params) => (
                  <TextField {...params} label="Timezone" />
                )}
                style={defaultStyle}
                // value={selectedStore}
                // onChange={(_order: any, newValue: Store | null) => setSelectedStore(newValue)}
                inputValue={selectedTimezone}
                defaultValue={defaultZone}
                getOptionLabel={(option) => option}
                onInputChange={(_order, newInputValue) =>
                  setSelectedTimezone(newInputValue)
                }
              />
            </div>
            <div className="hbox">
              <Autocomplete
                disablePortal
                id="combo-stores"
                options={stores}
                sx={{ width: "60%", paddingRight: "1em" }}
                getOptionLabel={(option) => qualifiedStoreName(option)}
                renderInput={(params) => (
                  <TextField {...params} label="Store" />
                )}
                style={defaultStyle}
                // value={selectedStore}
                // onChange={(_order: any, newValue: Store | null) => setSelectedStore(newValue)}
                inputValue={selectedStoreName}
                onInputChange={(_order, newInputValue) =>
                  setSelectedStoreName(newInputValue)
                }
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Start Date"
                  value={selectedDateRange.startDate}
                  onChange={(newValue: Date | null) => {
                    if (newValue && DateTime.fromJSDate(newValue).isValid) {
                      setSelectedDateRange((curr) => ({
                        ...curr,
                        startDate: newValue?.toISOString() ?? "",
                      }));
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <div style={{ width: "1em" }}></div>
                <DesktopDatePicker
                  label="End Date"
                  value={selectedDateRange.endDate}
                  onChange={(newValue: Date | null) => {
                    if (newValue && DateTime.fromJSDate(newValue).isValid) {
                      setSelectedDateRange((curr) => ({
                        ...curr,
                        endDate: newValue?.toISOString() ?? "",
                      }));
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <Button
                aria-label="update"
                onClick={handleUpdate}
                startIcon={<RefreshIcon />}
                style={defaultStyle}
                disabled={selectedStoreName.length < 1}
                sx={{ paddingLeft: "1em", marginLeft: "1em" }}
              >
                Update
              </Button>
            </div>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              style={headerStyle}
            >
              Orders
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <div className="hbox" style={searchBoxStyle}>
                <SearchIcon />
                <Input
                  placeholder="Search"
                  className="flex flex-1 mx-8"
                  disableUnderline
                  fullWidth
                  value={orderSearchText}
                  inputProps={{
                    "aria-label": "Search",
                  }}
                  onChange={(order: {
                    target: { value: SetStateAction<string> };
                  }) => setOrderSearchText(order.target.value)}
                  style={searchInputStyle}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  aria-label="export"
                  onClick={exportToCSV}
                  startIcon={<DownloadIcon />}
                  style={defaultStyle}
                  disabled={selectedStoreName.length < 1}
                  sx={{ paddingLeft: "1em" }}
                >
                  Export Orders
                </Button>
                <Button
                  aria-label="export totals"
                  onClick={exportTotalsToCSV}
                  startIcon={<DownloadIcon />}
                  style={defaultStyle}
                  disabled={selectedStoreName.length < 1}
                  sx={{ paddingLeft: "1em", marginLeft: "1em" }}
                >
                  Export Daily Totals
                </Button>
              </div>
            </div>
            {createTable<EventInfoExt>(
              orderHeadCells,
              orderOrder,
              orderOrderBy,
              orderRows,
              emptyOrderRows,
              ordersPage,
              orderRowsPerPage,
              orderToCell,
              handleOrdersRequestSort,
              handleOrdersChangePage,
              handleOrdersChangeRowsPerPage
            )}
            <div
              className="hboxa"
              style={{
                display: "grid",
                gap: "1.5rem",
                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                paddingBottom: "2em",
                paddingTop: "1em",
              }}
            >
              <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    disabled
                    id="outlined-disabled"
                    label="Total amount (EUR)"
                    value={totals?.amount.toFixed(2) || 0}
                    style={defaultStyle}
                    sx={{ width: "100%" }}
                  />
                  <IconButton
                    aria-label="copy"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        totals?.amount.toFixed(2).toString() || "0.00"
                      )
                    }
                    sx={{
                      marginLeft: "0.2em",
                      width: "2em",
                      height: "2em",
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </div>
              </div>
              <div style={{ gridColumnStart: 3, gridColumnEnd: 5 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    disabled
                    id="outlined-disabled"
                    label="Total number of items"
                    value={totals?.nitems || 0}
                    style={defaultStyle}
                    sx={{
                      width: "100%",
                    }}
                  />
                  <IconButton
                    aria-label="copy"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        totals?.nitems.toString() || "0"
                      )
                    }
                    sx={{
                      marginLeft: "0.2em",
                      width: "2em",
                      height: "2em",
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </div>
              </div>
              <div style={{ gridColumnStart: 5, gridColumnEnd: 7 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    disabled
                    id="outlined-disabled"
                    label="Total orders"
                    value={orders.length || 0}
                    style={defaultStyle}
                    sx={{
                      width: "100%",
                    }}
                  />
                  <IconButton
                    aria-label="copy"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        totals?.nitems.toString() || "0"
                      )
                    }
                    sx={{
                      marginLeft: "0.2em",
                      width: "2em",
                      height: "2em",
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </div>
              </div>

              {Object.keys(dailyTotals).map((key) => {
                return (
                  <div key={key}>
                    <Typography variant="h6" component="div">
                      {key}
                    </Typography>
                    <Typography variant="body1" component="div">
                      {dailyTotals[key].orders} orders
                    </Typography>
                    <Typography variant="body1" component="div">
                      {dailyTotals[key].nitems} items
                    </Typography>
                    <Typography variant="body1" component="div">
                      {dailyTotals[key].amount.toFixed(2)} EUR
                    </Typography>
                  </div>
                );
              })}
            </div>
          </Paper>
        </Box>
      </div>
    </div>
  );
}

export default OrderStatus;
